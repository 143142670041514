import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

//Bootstrap
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
//Images
// import Favicon from './../../resources/images/page/favicon.svg'
//Structures
import ButtonContact from './../pages/ButtonContactBold'

const Header = () => {

    // Change color on scroll
    const [navbar, setNavbar] = useState(false)
    const changeBackground = () => {
        if (window.scrollY >= 10) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeBackground);

    // Close navbar on click
    // https://stackoverflow.com/questions/32452695/react-bootstrap-how-to-collapse-menu-when-item-is-selected
    const [expanded, setExpanded] = useState(false);

    return (
        <header>
            <Container fluid className={navbar ? 'fixed-lg-top px-0 bg_header active' : 'fixed-lg-top px-0 bg_header'}>
                <div>
                    <Col xs={12}>
                        <Navbar bg="light" expand="lg" className="change_color py-4" expanded={expanded}>
                            {/* LOGO */}
                            {/* <NavLink exact to='/' className="py-2 px-0"><img src={Favicon} width="60px" className="logoNavbar img-fluid" alt="DDCTMD Logo" /></NavLink> */}

                            {/* LOGIN BUTTON */}
                            {/* <Button variant="info" className="login">
                                <a href="https://ddctmdtest.netlify.app/login">Login</a>
                            </Button> */}
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto align-items-center">
                                    <NavLink exact to='/' onClick={() => setExpanded(false)} className="pt-3 pt-lg-0" activeClassName="active_page">Home<span className="line"></span></NavLink>
                                    <NavLink exact to='/Products' onClick={() => setExpanded(false)} activeClassName="active_page">Our Products<span className="line"></span></NavLink>
                                    <NavLink exact to='/About' onClick={() => setExpanded(false)} activeClassName="active_page">About Us<span className="line"></span></NavLink>
                                    <NavLink exact to='/Impressum' onClick={() => setExpanded(false)} activeClassName="active_page">Impressum<span className="line"></span></NavLink>
                                    <ButtonContact title='Contact' setExpanded={setExpanded} />
                                    {/* BOOK A DEMO BUTTON */}
                                    {/* LOGIN BUTTON */}
                                    <Button variant="info" className="login">
                                        <a href="https://app.medai.ai/login" target="_blank" rel="noopener noreferrer">LOGIN</a>
                                    </Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </div>
            </Container>
        </header>
    );
}

export default Header;