import React from 'react';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

const TitleIconText = (title = "Title", subtitle = "Sub", brief = "Brief", description = "Description", images) => {
    return (
        <Row className="text-left align-items-center py-5">
            <Col xs={12} lg={4} className="pb-lg-5">
                <h2 className="text-uppercase">{title}</h2>
                <h2 className="text-uppercase"><strong>{subtitle}</strong></h2>
                <p className="pt-3 text_grey">{brief}</p>
                <FontAwesomeIcon className="text_blue pt-3" icon={faSlidersH} size="3x" />
                <p className="text-justify pt-3">{description}</p>
            </Col>
            {(images !== undefined) ?
                (<Col xs={12} lg={8} className="pt-3 pt-lg-0">
                    <Carousel className="text-center" indicators={false} slide={false} fade={true}>
                        {images.map((v, i) => {
                            return (
                                <Carousel.Item className="text-center">
                                    <img
                                        className="d-block"
                                        src={v}
                                        alt={i}
                                    />
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </Col>) :
                null
            }

        </Row >
    );
}

export default TitleIconText;