import React from 'react';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
//Images
import BackgroundVideo from "./../../resources/video/ddctmd_video.mp4"
// import { NavLink } from 'react-router-dom';
import ButtonContactBold from './../pages/ButtonContactBold'
const Home = () => {
    return (
        <Container fluid className="banner_home">
            <Row>
                <Col xs={12} className="d-flex align-items-end h-100">
                    <div className="video_container">
                        <video src={BackgroundVideo} autoPlay={true} loop muted />
                    </div>
                    <div className="video_overlay"></div>
                    <Container className="video_content px-0" fluid>
                        <Row className="h-100 align-items-center align-items-md-end p-md-5">
                            <Col xs={12}>
                                <h1 className="pb-3 title text-center text-md-left">MEDAI</h1>
                                <h4 className="text-center text-md-left">State of the Art, Business Intelligent Digital Solutions</h4>
                                <h4 className="text-center text-md-left pb-3">From Doctors for Doctors...</h4>
                                <ButtonContactBold title='BOOK A DEMO' />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;