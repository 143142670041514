import React, { useRef } from 'react'
// import { NavLink } from 'react-router-dom';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLaptopMedical, faPencilRuler, faCompactDisc, faSlidersH, faChartBar, faDungeon } from '@fortawesome/free-solid-svg-icons'
import { faPencilRuler, faCompactDisc, faSlidersH, faChartBar, faDungeon } from '@fortawesome/free-solid-svg-icons'
import { faAccusoft } from '@fortawesome/free-brands-svg-icons'
//Structures
// import Text from './../projectTemplates/Text'
// import TitleIconText from './../projectTemplates/TitleIconText'
import TextLeftCarouselRight from '../projectTemplates/TextLeftCarouselRight'
import TextRightCarouselLeft from '../projectTemplates/TextRightCarouselLeft'

//Resources
import MDHA from "./../../resources/images/images/projects/mdh.png"
import MDHB from "./../../resources/images/images/projects/mdhb.png"

import PD from "./../../resources/images/images/projects/paindraw.png"
import PD2 from "./../../resources/images/images/projects/pd2.png"

import DCTMDA from "./../../resources/images/images/projects/dctmda.png"
import DCTMDB from "./../../resources/images/images/projects/dctmdb.png"

import CHARTA from "./../../resources/images/images/projects/charta.png"
import CHARTB from "./../../resources/images/images/projects/chartb.png"

import HOME from "./../../resources/images/images/projects/home.png"
const Projects = () => {

    const one = useRef(null)
    const two = useRef(null)
    // const three = useRef(null)
    const four = useRef(null)
    const five = useRef(null)
    const six = useRef(null)
    const seven = useRef(null)
    const executeScroll_one = () => one.current.scrollIntoView()
    const executeScroll_two = () => two.current.scrollIntoView()
    // const executeScroll_three = () => three.current.scrollIntoView()
    const executeScroll_four = () => four.current.scrollIntoView()
    const executeScroll_five = () => five.current.scrollIntoView()
    const executeScroll_six = () => six.current.scrollIntoView()
    const executeScroll_seven = () => seven.current.scrollIntoView()

    return (
        <React.Fragment>
            <Container fluid className="banner_projects project_pills">
                <Container>
                    <Row className="d-flex align-items-center mh-100">
                        <Col xs={12} className="text-center pb-lg-5">
                            {/* <h1 className="uppercase text_white pb-5"><strong>AN ONLINE PORTFOLIO<br />MADE FOR CREATIVES</strong></h1> */}
                            {/* <p className="text_white">WITH ALL THE TOOLS A PROFESSIONAL NEEDS</p> */}
                            <Row className="mt-lg-5 pt-lg-5">
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_one()}><FontAwesomeIcon icon={faCompactDisc} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Medicos</p>
                                </Nav.Item>
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_two()}><FontAwesomeIcon icon={faPencilRuler} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Pain Draw</p>
                                </Nav.Item>
                                {/* <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_three()} ><FontAwesomeIcon icon={faLaptopMedical} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Dennis</p>
                                </Nav.Item> */}
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_four()}><FontAwesomeIcon icon={faAccusoft} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">DC-TMD</p>
                                </Nav.Item>
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_five()} ><FontAwesomeIcon icon={faSlidersH} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Reporty</p>
                                </Nav.Item>
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_six()} ><FontAwesomeIcon icon={faChartBar} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Auxilia</p>
                                </Nav.Item>
                                <Nav.Item className="col pb-4">
                                    <Nav.Link className="d-flex align-items-center justify-content-center text-center mx-auto" onClick={() => executeScroll_seven()} ><FontAwesomeIcon icon={faDungeon} size="3x" /></Nav.Link>
                                    <p className="pt-3 text-uppercase text_white">Orthico</p>
                                </Nav.Item>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="py-5 project">
                <Row>
                    <Col xs={12}>
                        <div ref={one} className="pt-5">
                            {TextLeftCarouselRight("Digitalization",
                                "MEDICOS",
                                "State of the art platform to share medical forms",
                                "Take advantage of modern technology and digitization combined with a state of the art platform to share medical forms, written examinations and administrative paperwork with your patients via Email reducing waiting time in the reception, danger of cross contamination and unauthorised access to data. Medicos also provides you with tips and support considering medication, missing medical info, can be a diagnostic assistance and much more!",
                                [MDHA, MDHB])}
                        </div>
                        <div ref={two} className="pt-5">
                            {TextRightCarouselLeft("Precision",
                                "Pain Draw",
                                "",
                                "A precise and reliable 2D or 3D documentation of Pain stating origin, end, pain intensity and spread that enables a precise documentation and a better understanding of the patient’s pain for differentiated diagnosis especially in myalgias and reflected pain.",
                                [PD, PD2])}
                        </div>
                        {/* <div ref={three} className="pt-5">
                            {TextLeftCarouselRight("Psychology",
                                "Dennis",
                                "Your psychological assistant diagnostic tool",
                                "Immediate sharing and assessment of several psychological tests to evaluate depression, fear, and psychosomatic symptoms as well as provides recommendations when necessary.",
                                [HOME])}
                        </div> */}
                        <div ref={four} className="pt-5">
                            {TextLeftCarouselRight("Diagnosis",
                                "Digital DC-TMD",
                                "Provides you with 100% immediate diagnostic accuracy with an automated version of the DC-TMD.",
                                "Multiple clinical visits? This is history! Share the Axis II examinations immediately with your patients, obtain immediate results, immediate reports with detailed analysis and much more.",
                                [DCTMDA, DCTMDB])
                            }
                        </div>
                        <div ref={five} className="pt-5">
                            {TextRightCarouselLeft("AI Powered",
                                "Reporty",
                                "",
                                "Automated data extraction tool helps automate medical reports and treatment suggestions based on previously determined decision trees.",
                                [CHARTA, CHARTB])
                            }
                        </div>
                        <div ref={six} className="pt-5">
                            {TextLeftCarouselRight("Statistics",
                                "Auxilia",
                                "",
                                "Immediate statistical analysis for your most important parameters, smart prioritisation and implementation of tasks, and patients admission up to the priority of their cases.",
                                [HOME])
                            }
                        </div>
                        <div ref={seven} className="pt-5">
                            {TextRightCarouselLeft("Analysis",
                                "Orthico",
                                "Orthodontic treatment based on internationally recognized parameters",
                                "AI powered analysis, developed especially for orthodontists, to evaluate the complexity of orthodontic treatment based on internationally recognized parameters such as: the Peer Assessment Rating index (PAR), the IOTN and many others. Orthico also allows digital immediate check ups and provides an AI powered cases follow up digital platform to make sure your patient is in the right step of the treatment. optimal for aligner cases as well as traditional orthodontics.",
                                [HOME])
                            }
                        </div>
                    </Col>
                </Row>
            </Container >
        </React.Fragment >
    );
}

export default Projects;