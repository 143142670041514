import React from 'react';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const AboutUs = () => {
    return (
        <React.Fragment>
            <Container fluid className="banner_aboutUs">
                <Row>
                    <Col xs={12} className="d-flex align-items-center text-center h-70 parallax">
                        <h1 className="w-100 text_white"><strong>About Us</strong></h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg_white d-flex align-items-center h-30">
                <Container>
                    <Col xs={12} className="py-5">
                        <h2 className="text-center"><strong>Innovation, Intelligence, Creativity</strong></h2>
                        <p className="text-center">We realise your vision, for a better present...and a wonderful future, for you, your patients and your beloved ones..for the generations to come by fulfilling your digital needs and implementing AI and digital based business intelligent solutions for you and your clinic.</p>
                    </Col>
                </Container>
            </Container>
            <Container fluid className="bg_grey py-5">
                <Container>
                    <Col xs={12} className="py-5">
                        <h2 className="text-left"><strong>The Opportunities</strong></h2>
                        <p className="text-justify">We develop custom solutions for everybody. No matter how small or large you are, if you have digitalization needs we are here to sit together with you, understand your goals, and create the best solutions that specifically address your clinic’s needs and goals.</p>
                    </Col>
                    <Col xs={12} className="py-5">
                        <h2 className="text-left"><strong>The Team</strong></h2>
                        <p className="text-justify">We are a team of Doctors, dentists, programmers and business development managers working together to create state of the art solutions that leverage AI, Blockchain and smart digitalization to create innovative solutions that solve problems in the healthcare domain and improve the life quality of doctors and patients.</p>
                    </Col>
                    <Col xs={12} className="py-5">
                        <h2 className="text-left"><strong>The Promise</strong></h2>
                        <p className="text-justify">Medai aims to provide the basis and the background for a new, optimised safe and smart healthcare system. With its several tools and platforms that provide an effective liaison between patients, clinics and doctors it allows you to keep all your medical data in one secure place and organise your appointments, medications, integrate physio training and much more…</p>
                    </Col>
                </Container>
            </Container>
        </React.Fragment>
    );
}

export default AboutUs;