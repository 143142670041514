import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'

//Header
import Header from './components/header/Header'
//Footer
import Footer from './components/footer/Footer'
//Pages
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import Projects from './components/pages/Projects2'
import BookADemo from './components/pages/BookADemo'
import NotFound from './components/NotFound'
import Impressum from './components/pages/Impressum'


//Bootstrap
import Button from 'react-bootstrap/Button';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'

function DDCTMD() {

  const [scrollTopIcon, setScrollTopIcon] = useState(false)
  const scrollTop = () => {
    if (window.scrollY >= 200) {
      setScrollTopIcon(true)
    } else {
      setScrollTopIcon(false)
    }
  }
  window.addEventListener('scroll', scrollTop);

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Router>
      <ScrollToTop>
        <Header />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/About" component={AboutUs} />
            <Route exact path="/Products" component={Projects} />
            <Route exact path="/BookADemo" component={BookADemo} />
            <Route exact path="/Impressum" component={Impressum} />

            <Route exact path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </main>
        <Footer />
        <Button onClick={() => scrollToTop()} variant="info" className={scrollTopIcon ? 'scroll_top_icon active' : 'scroll_top_icon'}><FontAwesomeIcon icon={faArrowCircleUp} size="2x" /></Button>
      </ScrollToTop>
    </Router>
  );
}

export default DDCTMD;
