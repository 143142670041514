import React from 'react';
import { Link } from 'react-router-dom'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//Images
// import Logo from './../../resources/images/page/logo.svg'
// import ButtonContact from './../pages/ButtonContact'
import ButtonContactBold from './../pages/ButtonContactBold'
const Footer = () => {
    // const year = new Date().getFullYear();
    return (
        <footer>
            <Container fluid className="bg_blue py-5">
                <Container>
                    <Row className="align-items-start">
                        <Col xs={12} sm={6} lg={4}>
                            <p><strong>About Us</strong></p>
                            <Link exact to='/About' activeClassName="active_page"><p>About Us</p></Link>
                            <Link exact to='/Products' activeClassName="active_page"><p>Our Products</p></Link>
                            {/* <p>Our Story</p>
                            <p>Careers</p> */}
                        </Col>

                        <Col xs={12} sm={6} lg={4}>
                            <p><strong>Contact Us</strong></p>
                            <ButtonContactBold title='Contact' />
                            {/* <Link exact to='/BookADemo' activeClassName="active_page"><p>Book a Demo</p></Link> */}
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <p><strong>Digital DCTMD</strong></p>
                            <a href="https://ddctmdtest.netlify.app/login" target="_blank" rel="noopener noreferrer"><p>Login</p></a>
                            {/* <p>Request Access</p>*/}
                        </Col>
                        <Col xs={12} className="text-center text-md-left py-4">
                            {/* <img src={Logo} alt="Logo" width="200px" /> */}
                        </Col>
                        <Col xs={12} className="pb-3 text-center">
                            <p>Medai helps doctors and dentists leverage the benefits of AI and digitalization for customised solutions and improved diagnostic accuracy.</p>
                        </Col>
                        <Col xs={12}>
                            <hr className="white" />
                        </Col>
                        <Col xs={12} className="pt-3 text-center">
                            <p>Copyright &copy; 2020 - Medai</p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </footer >
    );
}

export default Footer;