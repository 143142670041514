import React from 'react';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const AboutUs = () => {
    return (
        <React.Fragment>
            <Container fluid className="banner_aboutUs">
                <Row>
                    <Col xs={12} className="d-flex align-items-center text-center h-70 parallax">
                        <h1 className="w-100 text_white"><strong>Impressum</strong></h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg_white d-flex align-items-center h-30">
                <Container>
                    <Col xs={12} className="py-5">
                        <h2 className="text-center"><strong>Legal Notice</strong></h2>
                        <br />
                        <p className="text-justify">This legal notice also applies to our social media presences (Twitter, LinkedIn).
                            medai.ai GmbH
                            <br />
                            Joseph Haydn Straße 7<br />
                            68165 Mannheim, Germany<br />
                            <br />
                            Represented by: Dr. Bellarita Bechmann<br />
                            <br />
                            E-Mail: info@medai.ai<br />
                            Phone: +49 173 658 3553<br />
                            <br />
                            Register Court Mannheim<br />
                            HRB 744636<br />
                            VAT ID No.:38186/20628<br />
                            <br />
                            Responsible for the content according to § 18 Abs. 2 MStV<br />
                            Bellarita Bechmann<br />
                            <br />
                            ℅ Medai.ai GmbH<br />
                            Joseph Haydn Straße 7<br />
                            68165 Mannheim, Germany<br />
                        </p>
                    </Col>
                </Container>
            </Container>
            <Container fluid className="bg_grey py-5">
                <Container>
                    <Col xs={12} className="py-5">
                        <h2 className="text-center"><strong>Privacy Notice</strong></h2>
                        <p className="text-justify">
                            Website Status:<br />
                            <br />
                            The website <b>ddctmdtest.netlify.app and medai.ai</b> are currently in the test phase (beta).<br />
                            <br />
                            Disclaimer:<br />
                            <br />
                            The content of this website has been created with great care and is intended to provide accurate and up-to-date information. However, the provider cannot guarantee the accuracy, completeness, or timeliness of the information provided. The use of the website and its content is at the user's own risk.
                            <br />
                            <br /> Liability for Links:<br /><br />
                            This website may contain links to third-party websites. The provider is not responsible for the content of these external websites and does not endorse or support their content. The provider is not liable for any damages or losses incurred through the use of external links.
                            <br />  <br />Copyright:<br /><br />
                            The content, design, and structure of this website, including but not limited to text, graphics, images, logos, and other elements, are protected by copyright and other intellectual property rights. Any reproduction, modification, distribution, or other use of the content without the prior written consent of the provider is prohibited.
                            <br /> <br />Data Privacy:<br /><br />
                            The provider takes data privacy seriously and complies with applicable data protection laws. For more information on how personal data is collected, processed, and used on this website, please refer to our team.
                            <br /><br />Note:<br /><br />
                            This impressum (legal disclosure) is provided for informational purposes only and does not constitute legal advice. It is recommended to seek professional legal advice to ensure compliance with applicable laws and regulations in Germany.
                        </p>
                    </Col>
                </Container>
            </Container>
        </React.Fragment >
    );
}

export default AboutUs;