import React from 'react';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const BookADemo = () => {
    return (
        <Container fluid className="bg_demo py-5">
            <Container>
                <Row className="d-flex align-items-center">
                    <Col xs={12} md={6} className="p-5 white">
                        <h2 className="text-left"><strong className="underline_title">What to Expect from Our Demo</strong></h2>
                        <p className="text-justify pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p className="text-justify pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..</p>
                    </Col>
                    <Col xs={12} md={6} className="p-5">
                        <Form className="text-center card white">
                            <Form.Group controlId="name" className="text-left">
                                <Form.Label className="text-uppercase small">Name</Form.Label>
                                <Form.Control type="text" autocomplete="on" />
                            </Form.Group>
                            <Form.Group controlId="email" className="text-left">
                                <Form.Label className="text-uppercase small">Corporate Email</Form.Label>
                                <Form.Control type="email" autocomplete="on" />
                            </Form.Group>
                            <Form.Group controlId="message" className="text-left">
                                <Form.Label className="text-uppercase small">Message</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Form.Group className="pt-3 text-left">
                                <Button variant="info" type="submit">Submit</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default BookADemo;