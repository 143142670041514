import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// //Images
// import Logo from './../../resources/images/page/logo.svg'
// //Fontawesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
// import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
// import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const ButtonContactBold = ({ title }) => {

    const [name, sname] = useState("")
    const [email, semail] = useState("")
    const [message, smessage] = useState("")
    const [phone, sphone] = useState("")
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }

    return (
        <React.Fragment>
            <Button variant="info" className="contact_button" onClick={handleShow}>{title}<span className="line"></span></Button>
            <Modal show={show} onHide={handleClose} centered dialogClassName="modal-60w">
                <Modal.Body className="p-0 show-grid">
                    <Row className="mx-0">
                        <Col xs={12} lg={6} className="d-flex align-items-center text-center justify-content-center bg_image_contact modal-80vh order-2 order-lg-1">
                            {/* <Col xs={12}>
                                <Col xs={12} className="pb-5 px-0 px-lg-3">
                                    <img src={Logo} className="img-fluid" alt="DDCTMD Logo" width="250px" />
                                </Col>
                                <Col xs={12} className="pb-3 px-0 px-lg-3">
                                    <a className="d-flex align-items-center" href="https://goo.gl/maps/NkjpVyNJSdJLYuAN8" rel="noopener noreferrer" target="_blank">
                                        <Col xs={2} className="text-center">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                                        </Col>
                                        <Col xs={10}>
                                            <p>Address</p>
                                        </Col>
                                    </a>
                                </Col>
                                <Col xs={12} className="pb-3 px-0 px-lg-3">
                                    <a className="d-flex align-items-center" href="tel:+10000000000" rel="noopener noreferrer" target="_blank">
                                        <Col xs={2} className="text-center">
                                            <FontAwesomeIcon icon={faPhone} size="2x" />
                                        </Col>
                                        <Col xs={10}>
                                            <p>+1-000-000-00-00</p>
                                        </Col>
                                    </a>
                                </Col>
                                <Col xs={12} className="pb-3 px-0 px-lg-3">
                                    <a className="d-flex align-items-center" href="mailto:email@dentaldiagnosis.com?subject=Information request" rel="noopener noreferrer" target="_blank">
                                        <Col xs={2} className="text-center">
                                            <FontAwesomeIcon icon={faEnvelope} size="2x" />
                                        </Col>
                                        <Col xs={10}>
                                            <p>email@dentaldiagnosis.com</p>
                                        </Col>
                                    </a>
                                </Col>
                                <Col xs={12} className="pb-3 px-0 px-lg-3">
                                    <a className="d-flex align-items-center" href="https://www.instagram.com" rel="noopener noreferrer" target="_blank">
                                        <Col xs={2} className="text-center">
                                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                                        </Col>
                                        <Col xs={10}>
                                            <p>@instagram</p>
                                        </Col>
                                    </a>
                                </Col>
                                <Col xs={12} className="pb-3 px-0 px-lg-3">
                                    <a className="d-flex align-items-center" href="https://www.facebook.com" rel="noopener noreferrer" target="_blank">
                                        <Col xs={2} className="text-center">
                                            <FontAwesomeIcon icon={faFacebookF} size="2x" />
                                        </Col>
                                        <Col xs={10}>
                                            <p>Facebook</p>
                                        </Col>
                                    </a>
                                </Col>
                            </Col> */}
                        </Col>
                        <Col xs={12} lg={6} className="d-flex align-items-center bg_blue p-5 order-1 order-lg-2">
                            <Form className="text-center content_contact">
                                <h2 className="text-uppercase pb-3"><strong>Contact</strong></h2>
                                {/* <p className="pb-5">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis.</p> */}
                                <Form.Group controlId="name">
                                    <Form.Label className="text-uppercase small">Full name</Form.Label>
                                    <Form.Control type="text" placeholder="Your Full Name" value={name} onChange={(e) => sname(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label className="text-uppercase small">Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email Address" value={email} onChange={(e) => semail(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="phone">
                                    <Form.Label className="text-uppercase small">Phone number</Form.Label>
                                    <Form.Control type="phone" placeholder="Your Phone Number" value={phone} onChange={(e) => sphone(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="phone">
                                    <Form.Label className="text-uppercase small">Message</Form.Label>
                                    <Form.Control type="phone" placeholder="Message" value={message} onChange={(e) => smessage(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="pt-5 text-center text-lg-right">
                                    <Button variant="info" type="submit" onClick={(e) => {
                                        e.preventDefault();
                                        var axiosClient = axios.create({
                                            //    baseURL: 'http://localhost:57112'
                                            baseURL: 'https://ddctmdapi.azurewebsites.net'
                                        });
                                        axiosClient.post("/g/contactus", { phone, email, message, name })
                                            .then(() => {
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: "Successful"
                                                })
                                                setShow(false)
                                            })
                                            .catch(() => {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: "Network Error"
                                                })
                                            })
                                    }}
                                    >Submit</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
}

export default ButtonContactBold;